<template>
  <div class="medic-info">
    <div class="medic-container flex-start-start">
      <div class="info-left" id="medicLeft" v-noCopy>
        <!-- 面包屑 -->
        <div class="content breadcrumb">
          <el-breadcrumb separator="/">
            <!--  :to="{ path: '/' }" -->
            <!--  :to="{ path: '/medicine/patentMedicine/SearchContent' }" -->
            <el-breadcrumb-item><a href="#/medicine/herbalMedicine/searchContent?medicType=1&searchType=-1&searchText=">中草药</a></el-breadcrumb-item>
            <!-- <a href="/"></a> -->
            <el-breadcrumb-item>中草药详情页</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 名称 -->
        <div class="content info-name-box">
          <div class="name-wrapper flex-start-center">
            {{patentInfo.drugName}}
            <Tag style="margin-left: 6px" :productItem="patentInfo" />
          </div>
        </div>
        <!-- 基本资料 -->
        <div class="info-title-box" id="information" v-anchor="'#information'">
          <div class="info-title flex-start-center" style="margin-bottom: 14px">
            <span>基本资料</span>
            <div class="line flex-1"></div>
          </div>
          <div class="flex-start-center">
            <div class="content information">
              <div class="box flex-start-center" v-if="patentInfo.drugName">
                <span class="name">中药学名：</span>
                <span class="text flex-1">{{patentInfo.drugName | removeHtml}}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.enName">
                <span class="name">英文名：</span>
                <span class="text flex-1">{{patentInfo.enName | removeHtml}}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.drugPinyinName">
                <span class="name">拼音：</span>
                <span class="text flex-1">{{patentInfo.drugPinyinName | removeHtml}}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.aliasName">
                <span class="name">别名：</span>
                <span class="text flex-1">{{patentInfo.aliasName | removeHtml}}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.category">
                <span class="name">科别：</span>
                <span class="text flex-1">{{patentInfo.category | removeHtml}}</span>
              </div>
              <div class="box flex-start-center" v-if="patentInfo.produceAddress">
                <span class="name">产地：</span>
                <span class="text flex-1">{{patentInfo.produceAddress | removeHtml}}</span>
              </div>
            </div>
            <div class="flex-1 flex-center-center">
              <div class="image-box" @click="showDialog" v-if="patentInfo.pictureList && patentInfo.pictureList.length > 0">
                <img :src="item.picUrl" v-for="(item,index) in patentInfo.pictureList" :key="index" v-if="item.ifCover == 1" alt="">
                <p class="more medic-hover-info">更多图片</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 毒性研究 -->
        <div class="info-title-box" id="duStudy" v-anchor="'#duStudy'" v-if="patentInfo.toxicityLevel || patentInfo.toxicInn || patentInfo.poisoningLethalDose || patentInfo.poisoningAppearance || patentInfo.toxicTargetOrgan || patentInfo.poisoningMechanism || patentInfo.poisoningTreatment">
          <div class="info-title dashed flex-start-center">
            <span>毒性研究</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <div class="toxicity">
              <div class="box flex-column-start-start" v-if="patentInfo.toxicityLevel">
                <span class="name">毒性分级：</span>
                <span class="text flex-1">{{patentInfo.toxicityLevel == 1 ? '有毒' : patentInfo.toxicityLevel == 2 ? '大毒' : patentInfo.toxicityLevel == 3 ? '小毒' : ''}}</span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.toxicInn">
                <span class="name">有毒成分：</span>
                <span class="text flex-1" v-html="patentInfo.toxicInn"></span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.poisoningLethalDose">
                <span class="name">中毒/致死剂量：</span>
                <span class="text flex-1" v-html="patentInfo.poisoningLethalDose"></span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.poisoningAppearance">
                <span class="name">中毒症状：</span>
                <span class="text flex-1" v-html="patentInfo.poisoningAppearance"></span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.toxicTargetOrgan">
                <span class="name">毒性靶器官：</span>
                <span class="text flex-1" v-html="patentInfo.toxicTargetOrgan"></span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.poisoningMechanism">
                <span class="name">中毒机理：</span>
                <span class="text flex-1" v-html="patentInfo.poisoningMechanism"></span>
              </div>
              <div class="box flex-column-start-start" v-if="patentInfo.poisoningTreatment">
                <span class="name">中毒后处理：</span>
                <span class="text flex-1" v-html="patentInfo.poisoningTreatment"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 中药简介 -->
        <div class="info-title-box" id="introduction" v-anchor="'#introduction'" v-if="patentInfo.introduction">
          <div class="info-title flex-start-center">
            <span>中药简介</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.introduction"></p>
          </div>
        </div>
        <!--  外观描述 -->
        <div class="info-title-box" id="appearance" v-anchor="'#appearance'" v-if="patentInfo.appearance">
          <div class="info-title flex-start-center">
            <span> 外观描述</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.appearance"></p>
          </div>
        </div>
        <!-- 性状 -->
        <div class="info-title-box" id="description" v-anchor="'#description'" v-if="patentInfo.description">
          <div class="info-title flex-start-center">
            <span>性状</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.description"></p>
          </div>
        </div>
        <!-- 性味与归经 -->
        <div class="info-title-box" id="characterMeridianTropism" v-anchor="'#characterMeridianTropism'" v-if="patentInfo.characterMeridianTropism">
          <div class="info-title flex-start-center">
            <span>性味与归经</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.characterMeridianTropism"></p>
          </div>
        </div>
        <!-- 活性成分 -->
        <div class="info-title-box" id="activeComponent" v-anchor="'#activeComponent'" v-if="patentInfo.activeComponent">
          <div class="info-title flex-start-center">
            <span>活性成分</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.activeComponent"></p>
          </div>
        </div>
        <!-- 功能主治 -->
        <div class="info-title-box" id="indications" v-anchor="'#indications'" v-if="patentInfo.indications">
          <div class="info-title flex-start-center">
            <span>功能主治</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.indications"></p>
          </div>
        </div>
        <!-- 药理作用 -->
        <div class="info-title-box" id="mechanismAction" v-anchor="'#mechanismAction'" v-if="patentInfo.mechanismAction">
          <div class="info-title flex-start-center">
            <span>药理作用</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.mechanismAction"></p>
          </div>
        </div>
        <!-- 用法用量 -->
        <div class="info-title-box" id="dosage" v-anchor="'#dosage'" v-if="patentInfo.dosage">
          <div class="info-title flex-start-center">
            <span>用法用量</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.dosage"></p>
          </div>
        </div>
        <!-- 不良反应 -->
        <div class="info-title-box" id="adverseReaction" v-anchor="'#adverseReaction'" v-if="patentInfo.adverseReaction">
          <div class="info-title flex-start-center">
            <span>不良反应</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.adverseReaction"></p>
          </div>
        </div>
        <!-- 禁忌症 -->
        <div class="info-title-box" id="contraindication" v-anchor="'#contraindication'" v-if="patentInfo.contraindication">
          <div class="info-title flex-start-center">
            <span>禁忌症</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.contraindication"></p>
          </div>
        </div>
        <!-- 炮制方法 -->
        <div class="info-title-box" id="processingMethod" v-anchor="'#processingMethod'" v-if="patentInfo.processingMethod">
          <div class="info-title flex-start-center">
            <span>炮制方法</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.processingMethod"></p>
          </div>
        </div>
        <!-- 临床案例 -->
        <div class="info-title-box" id="clinicalCases" v-anchor="'#clinicalCases'" v-if="patentInfo.clinicalCases">
          <div class="info-title flex-start-center">
            <span>临床案例</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.clinicalCases"></p>
          </div>
        </div>
        <!-- 参考资料 -->
        <div class="info-title-box" id="referenceMaterial" v-anchor="'#referenceMaterial'" v-if="patentInfo.referenceMaterial">
          <div class="info-title flex-start-center">
            <span>参考资料</span>
            <div class="line flex-1"></div>
          </div>
          <div class="content">
            <p class="info-info" v-html="patentInfo.referenceMaterial"></p>
          </div>
        </div>
      </div>
      <!-- 锚点 -->
      <div class="info-right">
        <ul class="info-anchor">
          <li class="item" :class="{active: anchorIndex == item.href}" v-for="(item,index) in anchorList" :key="index" v-if="!item.hidden">
            <!-- :href="item.href" -->
            <a class="medic-hover-info" @click="goHref(item)">{{item.name}}</a>
            <ul class="sun-panel" v-if="item.subList">
              <li class="panel-item medic-hover-info" v-for="(child,childIndex) in item.subList" :key="childIndex" v-if="!child.hidden">{{child.name}}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <component :is="model" :imageList="patentInfo.pictureList" @close="model = null"></component>
  </div>
</template>

<script>
import Tag from '@/views/herbalMedicine/components/tag'
import DialogSwiper from '@/views/herbalMedicine/components/DialogSwiper'
import { herbalDrugInfo } from '@/api/herbalMedicine'
export default {
  data() {
    return {
      anchorIndex: '#information',
      model: null,
      patentInfo: {},
      anchorList: {
        information: {
          href: '#information',
          name: '基本资料',
          subList: {
            drugName: {name: '中药学名'},
            enName: {name: '英文名'},
            drugPinyinName: {name: '拼音'},
            aliasName: {name: '别名'},
            category: {name: '科别'},
            produceAddress: {name: '产地'}
          }
        },
        duStudy: {
          href: '#duStudy',
          name: '毒性研究',
          subList: {
            toxicityLevel: {name: '毒性分级'},
            toxicInn: {name: '有毒成分'},
            poisoningLethalDose: {name: '中毒/致死剂量'},
            poisoningAppearance: {name: '中毒症状'},
            toxicTargetOrgan: {name: '毒性靶器官'},
            poisoningMechanism: {name: '中毒机理'},
            poisoningTreatment: {name: '中毒后处理'},
          }
        },
        introduction: {
          href: '#introduction',
          name: '中药简介',
        },
        appearance: {
          href: '#appearance',
          name: '外观描述',
        },
        description: {
          href: '#description',
          name: '性状',
        },
        characterMeridianTropism: {
          href: '#characterMeridianTropism',
          name: '性味与归经',
        },
        activeComponent: {
          href: '#activeComponent',
          name: '活性成分',
        },
        indications: {
          href: '#indications',
          name: '功能主治',
        },
        mechanismAction: {
          href: '#mechanismAction',
          name: '药理作用',
        },
        dosage: {
          href: '#dosage',
          name: '用法用量',
        },
        adverseReaction: {
          href: '#adverseReaction',
          name: '不良反应',
        },
        contraindication: {
          href: '#contraindication',
          name: '禁忌症',
        },
        processingMethod: {
          href: '#processingMethod',
          name: '炮制方法',
        },
        clinicalCases: {
          href: '#clinicalCases',
          name: '临床案例',
        },
        referenceMaterial: {
          href: '#referenceMaterial',
          name: '参考资料',
        }
      },
      anchorIdList: [],
      anchorScroll: null, // 滚动距离顶部高度
    }
  },
  created() {
    this.initInfo();
  },
  mounted() {
    this.$nextTick(res => {
      window.onscroll = e => {
        this.anchorScroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        Object.keys(this.anchorIdList).map(res => {
          if(this.anchorScroll >= this.anchorIdList[res]) {
            this.anchorIndex = res;
          }
        })
      }
    })
  },
  directives: {
    anchor: {
      inserted(el, { value }, vnode) {
        if(value) {
          let _this = vnode.context;
          _this.anchorIdList[value] = el.offsetTop
        }
      }
    }
  },
  methods: {
    initInfo() {
      console.log('查询中成药详情数据')
      herbalDrugInfo({ drugId: this.$route.params.drugId }).then(res => {
        console.log(res)
        this.patentInfo = res;
        // 处理锚点
        this.hiddenAnchor();
      }).catch(error => {
        this.$message.error(error.msg)
      })
    },
    // 处理锚点显示问题
    hiddenAnchor() {
      Object.keys(this.anchorList).map(item => {
        Object.keys(this.patentInfo).map(patent => {
          // 有子集
          if(this.anchorList[item].subList) {
            Object.keys(this.anchorList[item].subList).map(child => {
              // 没有相关字段 或没值
              if(!this.patentInfo[child]) {
                this.anchorList[item].subList[child].hidden = true;
              }
            })
          }
          // 特殊处理
          if(item !== 'information' && item !== 'duStudy' && item !== 'population' && item !== 'enterprise'){
            if(!this.patentInfo[item])  // 返回字段没值 隐藏
              this.anchorList[item].hidden = true;
          } else {
            // 初始化父节点不显示
            let hidden = true;
            Object.keys(this.anchorList[item].subList).map(child => {
              // 找到有显示节点 父节点就显示
              if(!this.anchorList[item].subList[child].hidden)
                hidden = false
              this.anchorList[item].hidden = hidden;
            })
          }
        })
      })
      // 处理默认显示锚点
      for(var item in this.anchorList) {
        if(!this.anchorList[item].hidden) {
          this.anchorIndex = this.anchorList[item].href
          return;
        }
      }
    },
    showDialog() {
      this.model = DialogSwiper;
    },
    goHref(item) {
      document.querySelector(item.href) && document.querySelector(item.href).scrollIntoView(true);
      this.anchorIndex = item.href
    }
  },
  filters: {
    ellipsis(val) {
      if(val && val.length > 15 * 2) {
        val = val.substring(0, 15 * 2) + '...'
      }
      return val
    }
  },
  components: {
    Tag,
    DialogSwiper
  },
  watch: {
    $route(to) {
      location.reload();
    }
  },
  beforeDestroy() {
    window.onscroll = () => {}
  }
}
</script>

<style lang="scss" scope>
@import './../layout/info.scss';
</style>